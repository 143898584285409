import { reactive } from 'vue'

import axios from 'axios'
import type { AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios'

import { isFunction, isArray, head, pullAt } from 'lodash-es'

interface ResponseData<T = any> {
  data: T[]
  meta?: object
}

interface AjaxConfig {
  action: string
  params?: any
  convert?: {
    client?: (data: any[], res: ResponseData) => void
    server?: (data: any) => void
  }
  lazy?: boolean
}

interface TableConfigs {
  columns: any[]
  paging?: ETablePaging
  ajax?: {
    get: AjaxConfig
  }
  [index: string]: any
}

export interface TableAction {
  data: any[]
  // TODO: hooks设计应该和组件库无关，有空再重构。
  columns: any[]
  paging?: ETablePaging
  loading: boolean
  get: () => void
}
export interface ETablePaging {
  itemCount?: number
  pageIndex: number
  pageSize: number
}

type Method = 'get' | 'delete' | 'post' | 'put'

const request = axios.create({ baseURL: '/enoquote' })

request.interceptors.request.use(
  (config: AxiosRequestConfig) => {
    return config
  }
)

request.interceptors.response.use(
  (res: AxiosResponse) => {
    return Promise.resolve(res.data)
  },
  (err: AxiosError) => {
    if (err.response?.status === 403) {
      if (!err.response.data.errors[0].shouldNotNotification) {
        // notification.info({ message: '您当前无此操作权限' })
      }
      return Promise.resolve(err)
    }

    // if (err.response?.data.errors && err.response?.data.errors[0]) notification.error(err.response?.data.errors[0])
    return Promise.reject(err)
  }
)

function useAjax (configs: AjaxConfig) {
  let { action, params, convert, lazy = false } = configs
  let paramsCache = params
  let [httpVerb, path] = action.split(' ')
  let url: string = ''
  let method: Method = 'get'
  const ajaxConfig: AxiosRequestConfig = { method, url }

  const run = async () => {
    ajax.loading = true
    params = isFunction(paramsCache) ? paramsCache() : paramsCache
    if (!isArray(params)) params = [params]
    method = httpVerb.toLowerCase() as Method
    url = ''
    path
      .split('/')
      .forEach((str) => str && (url += `/${str.startsWith(':') ? head(params) && pullAt(params, 0) : str}`))
    ajaxConfig.url = url
    ajaxConfig.method = method
    switch (ajaxConfig.method) {
      case 'get':
        ajaxConfig.params = params[0]
        convert && convert.server && convert.server(ajaxConfig.params)
        break
      case 'post':
      case 'put':
        params[0] && (ajaxConfig.data = { data: [params[0]] })
        convert && convert.server && convert.server(ajaxConfig.data)
        break
    }

    const res = await request(ajaxConfig)
    // axios 类型推断没有把拦截器考虑进去，这里的res实际上是res.data
    ajax.data = res.data && (convert && convert.client ? convert.client(res.data, res) : res.data)
    ajax.loading = false
    return res
  }

  const ajax = reactive(
    {
      loading: false,
      data: [] as any[],
      run
    }
  )

  if (!lazy) ajax.run()
  return ajax
}

export function useTable (tableConfigs: TableConfigs): TableConfigs & TableAction & any {
  const table = reactive<TableAction & TableConfigs>(
    {
      data: [],
      columns: [],
      loading: false,
      currentRow: null,
      paging: {
        pageIndex: 1,
        itemCount: 0,
        pageSize: 10
      },
      get: () => {}
    }
  )
  table.currentChange = (row: any) => (table.currentRow = row)
  let AjaxConfig
  let request: { loading: boolean; data: any[]; run: () => Promise<any> }
  Object.entries(tableConfigs).forEach(
    ([key, config]) => {
      switch (key) {
        case 'columns':
          table.columns = config
          break
        case 'data':
          table.data = config
          break
        case 'ajax':
          Object.entries(config).forEach(
            ([subKey, subConfig]: [string, any]) => {
              let key = subKey.toLowerCase()
              switch (key) {
                case 'get':
                  AjaxConfig = Object.assign({}, subConfig)
                  AjaxConfig.lazy = true
                  AjaxConfig.params = () => {
                    let params = isFunction(subConfig.params) ? subConfig.params() : subConfig.params || {}
                    return Object.assign(
                      params,
                      {
                        pageIndex: table.paging!.pageIndex,
                        pageSize: table.paging!.pageSize
                      }
                    )
                  }
                  request = useAjax(AjaxConfig)
                  table.get = () => {
                    table.loading = true
                    request.run().then(
                      (res) => {
                        table.data = request.data
                        table.loading = false
                        if (res.meta.paging) table.paging = res.meta.paging
                      }
                    )
                  }
                  break
                default:
              }
            }
          )
          break
        default:
          table[key] = config
      }
    }
  )

  table.get()
  return table
}
