import { reactive } from 'vue'
import Websdk from 'easemob-websdk'
import moment from 'moment'

import { toNumber } from 'lodash-es'
import to from 'await-to-js'
import { notification as Notification } from 'ant-design-vue'

import { useAjax, ajax } from '@utils/useAjax'
interface LookupDto {
  code: string
  message: string
}

enum Status {
  UnLogin = 0,
  Logined = 1
}

interface FriendInfo {
  id?: number
  name: string
  chatAccount: string
  cellphone: string
  companyName: string
  tenantId?: string
  type: LookupDto
  supplierId?: string
}

export interface Friend {
  id?: number
  me: number
  friend: number
  friendInfo: FriendInfo
  notes: string
  groupId: number
  messages: Message[]
}

export interface Group {
  id: number
  name: string
}

export interface Message {
  id: string
  to: string
  type: string
  contentsType: string
  sourceMsg: string
  from: string
  data: string
  time: number
  isRead: Boolean
  ext: any
}

interface State {
  accessToken: string
  visible: boolean
  status: Status
  user: FriendInfo
  friends: Friend[]
  groups: Group[]
  unReadMessages: { id: string; count: number }[]
  current: Partial<Friend>
  dialog: {
    visible: boolean
    title: string
    type: '' | 'add-group' | 'modify-group' | 'remark-friend' | 'show-image' | 'order'
    data: any
    errorText: string
  }
}

const protocol = window.location.protocol

const config = {
  socketServer: `${protocol}://im-api-v2.easemob.com/ws`,
  restServer: `${protocol}://a1.easemob.com`,
  appKey: '1125161114178428#enochcloudquoted',
  https: false,
  isHttpDNS: true,
  autoReconnectNumMax: 2,
  autoReconnectInterval: 5
}

const conn = new Websdk.connection(
  {
    appKey: config.appKey,
    isHttpDNS: config.isHttpDNS,
    https: config.https,
    url: config.socketServer,
    apiUrl: config.restServer,
    autoReconnectNumMax: config.autoReconnectNumMax,
    autoReconnectInterval: config.autoReconnectInterval
  }
)

const stateInit = (props?: Partial<State>): State =>
  Object.assign(
    {
      accessToken: '',
      visible: false,
      status: Status.UnLogin,
      user: { name: '', chatAccount: '', companyName: '', type: { code: '', message: '' } },
      friends: [],
      groups: [],
      current: {},
      unReadMessages: [],
      dialog: {
        visible: false,
        title: '',
        type: '',
        data: null,
        errorText: ''
      }
    },
    props
  )

const state = reactive<State>(stateInit())

const getHuanxinFriend = () =>
  ajax.get(`/${localStorage.getItem('ENOQUOTE_ROLE')?.toLowerCase()}/huanxinfriend`).then(
    (res) => {
      state.friends = res.data.data.map(
        (item: Friend) => {
          const current = state.friends.find((friend) => friend.id === item.id)
          return Object.assign(
            item,
            { groupId: item.groupId || 0, messages: current ? current.messages : [], notes: item.notes || '' }
          )
        }
      )
      if (state.current.id) {
        const current = state.friends.find((friend) => friend.id === state.current.id)
        if (current) Object.assign(state.current, current)
        else Object.assign(state.current, {})
      }
    }
  )

const getHuanxinGroup = () =>
  ajax.get(`/${localStorage.getItem('ENOQUOTE_ROLE')?.toLowerCase()}/huanxingroup`).then(
    (res) => {
      res.data.data.unshift({ id: 0, name: '联系人' })
      state.groups = res.data.data
    }
  )

const addHuanxinFriend = (friendIds: string[]) =>
  ajax.post(`/${localStorage.getItem('ENOQUOTE_ROLE')?.toLowerCase()}/huanxinfriend`, { data: friendIds })

const getHuanxinFriendById = (friendId: Number) =>
  ajax.get(`/${localStorage.getItem('ENOQUOTE_ROLE')?.toLowerCase()}/huanxinfriend/${friendId}`)

const deleteHuanxinFriend = (friendId: Number) =>
  ajax.delete(`/${localStorage.getItem('ENOQUOTE_ROLE')?.toLowerCase()}/huanxinfriend/${friendId}/delete`)

const deleteHuanxinGroup = (groupId: Number) =>
  ajax.delete(`/${localStorage.getItem('ENOQUOTE_ROLE')?.toLowerCase()}/huanxingroup/${groupId}/delete`)

const receivedMessage = async (message: Message) => {
  message.from = message.from.toUpperCase()
  message.time = Number(message.time)
  message.isRead = state.current && state.current.friendInfo?.chatAccount === message.from
  const friend = state.friends.find((item) => item.friendInfo.chatAccount === message.from)
  if (friend) {
    friend.messages.push(message)
  } else {
    const [addErr, addRes] = await to(addHuanxinFriend([message.from]))
    if (addErr) return
    const [getErr, getRes] = await to(getHuanxinFriendById(addRes?.data.data[0]))
    if (getErr) return
    const [mesErr, mesRes] = await to(getHistoryMessages(message.from, 10, false))
    if (mesErr) return
    state.friends.push(
      Object.assign(getRes?.data.data[0], { groupId: 0, notes: '', messages: mesRes?.data })
    )
  }
}

conn.listen(
  {
    onOpened: async () => {
      changeState({ status: Status.Logined })
      await getHuanxinFriend()
      await getHuanxinGroup()
      for (const friend of state.friends) {
        try {
          friend.messages = (await getHistoryMessages(friend.friendInfo.chatAccount, 20, false)).data
        } catch (e) {
          console.log(e)
        }
      }
    },
    onClose: () => {
      console.log('连接断开啦~')
    },
    onTextMessage: receivedMessage,
    onCustomMessage: receivedMessage,
    onError: (error: any) => {
      console.log('hx error----', error)
    }
  }
)

const changeState = (states: Partial<State>) => Object.assign(state, states)

const { run: noteHuanxinFriend } = useAjax(
  {
    action: `POST /${localStorage.getItem('ENOQUOTE_ROLE')?.toLowerCase()}/huanxinfriend/notes`,
    params: () => Object.assign(state.dialog.data, { groupId: state.dialog.data.groupId || '' }),
    lazy: true
  }
)

const { run: groupHuanxinFriend } = useAjax(
  {
    action: `POST /${localStorage.getItem('ENOQUOTE_ROLE')?.toLowerCase()}/huanxinfriend/togroup`,
    params: () => Object.assign(state.dialog.data, { groupId: state.dialog.data.groupId || '' }),
    lazy: true
  }
)

const { run: postHuanxinGroup } = useAjax(
  {
    action: `POST /${localStorage.getItem('ENOQUOTE_ROLE')?.toLowerCase()}/huanxingroup`,
    params: () => state.dialog.data,
    lazy: true
  }
)

const { run: putHuanxinGroup } = useAjax(
  {
    action: `PUT /${localStorage.getItem('ENOQUOTE_ROLE')?.toLowerCase()}/huanxingroup`,
    params: () => state.dialog.data,
    lazy: true
  }
)

async function getUserInfoById (userIds: string[]): Promise<{ [Index: string]: { nickname: string } }> {
  return new Promise(
    async (resolve, reject) => {
      try {
        const { data: roster } = await WebIM.conn.fetchUserInfoById(userIds)
        resolve(roster)
      } catch (e) {
        reject(e)
      }
    }
  )
}

async function getHistoryMessages (
  queue: string,
  count: number,
  isGroup: boolean
): Promise<{ hxId: string; data: Message[] }> {
  return new Promise(
    (resolve, reject) => {
      const options = {
        queue,
        isGroup,
        count,
        success: (res: any) => {
          resolve(
            {
              hxId: queue,
              data: res.map((item: Message) => Object.assign(item, { time: toNumber(item.time), isRead: true }))
            }
          )
        },
        fail: (err: any) => reject(err)
      }
      WebIM.conn.fetchHistoryMessages(options)
    }
  )
}

async function sendMessage ({ type = 'text', size = '', message }: { type?: string; size?: string; message: string }) {
  return new Promise(
    (resolve, reject) => {
      if (!message) {
        Notification.error({ message: '不能发送空消息' })
        return reject('不能发送空消息')
      }
      const id = WebIM.conn.getUniqueId()
      const chatType = 'singleChat'
      const to = state.current.friendInfo!.chatAccount
      const from = state.user.chatAccount

      let mo
      const messageOptions = {
        msg: message,
        to,
        from,
        chatType,
        ext: {},
        success: (_: string, serverMsgId: string) => {
          const msg = {
            id: serverMsgId,
            to,
            type: 'chat',
            contentsType: 'TEXT',
            data: message,
            sourceMsg: message,
            from,
            time: +moment(),
            isRead: true,
            ext: {}
          }
          state.current.messages!.push(msg)
          return resolve({ type: 'text', message: msg })
        },
        fail: (err: any) => {
          Notification.error({ message: '消息发送失败' })
          return reject({ type: 'err', message: err })
        }
      }

      switch (type) {
        case 'text':
          mo = new Websdk.message('txt', id)
          break
        case 'face':
          messageOptions.ext = { type: 'FACE', url: message }
          messageOptions.success = (_: string, serverMsgId: string) => {
            const msg = {
              id: serverMsgId,
              to,
              type: 'chat',
              contentsType: 'TEXT',
              data: message,
              sourceMsg: message,
              from,
              time: +moment(),
              isRead: true,
              ext: messageOptions.ext
            }
            state.current.messages!.push(msg)
            return resolve({ type, message: msg })
          }
          mo = new Websdk.message('custom', id)
          break
        case 'img':
          messageOptions.ext = { type: 'IMAGE', url: message }
          messageOptions.success = (_: string, serverMsgId: string) => {
            const msg = {
              id: serverMsgId,
              to,
              type: 'chat',
              contentsType: 'TEXT',
              data: message,
              sourceMsg: message,
              from,
              time: +moment(),
              isRead: true,
              ext: messageOptions.ext
            }
            state.current.messages!.push(msg)
            return resolve({ type, message: msg })
          }
          mo = new Websdk.message('custom', id)
          break
        case 'pdf':
          messageOptions.ext = { type: 'PDF', url: message, size }
          messageOptions.success = (_: string, serverMsgId: string) => {
            const msg = {
              id: serverMsgId,
              to,
              type: 'chat',
              contentsType: 'TEXT',
              data: message,
              sourceMsg: message,
              from,
              time: +moment(),
              isRead: true,
              ext: messageOptions.ext
            }
            state.current.messages!.push(msg)
            return resolve({ type, message: msg })
          }
          mo = new Websdk.message('custom', id)
          break
        case 'doc':
          messageOptions.ext = { type: 'DOC', url: message, size }
          messageOptions.success = (_: string, serverMsgId: string) => {
            const msg = {
              id: serverMsgId,
              to,
              type: 'chat',
              contentsType: 'TEXT',
              data: message,
              sourceMsg: message,
              from,
              time: +moment(),
              isRead: true,
              ext: messageOptions.ext
            }
            state.current.messages!.push(msg)
            return resolve({ type, message: msg })
          }
          mo = new Websdk.message('custom', id)
          break
        case 'xls':
          messageOptions.ext = { type: 'XLS', url: message, size }
          messageOptions.success = (_: string, serverMsgId: string) => {
            const msg = {
              id: serverMsgId,
              to,
              type: 'chat',
              contentsType: 'TEXT',
              data: message,
              sourceMsg: message,
              from,
              time: +moment(),
              isRead: true,
              ext: messageOptions.ext
            }
            state.current.messages!.push(msg)
            return resolve({ type, message: msg })
          }
          mo = new Websdk.message('custom', id)
          break
        // case 'card':
        //   messageOptions.ext = { type: 'CARD', url: message }
        //   messageOptions.success = (_: string, serverMsgId: string) => {
        //     const msg = {
        //       id: serverMsgId,
        //       to,
        //       type: 'chat',
        //       contentsType: 'TEXT',
        //       data: message,
        //       sourceMsg: message,
        //       from,
        //       time: +moment(),
        //       isRead: true,
        //       ext: messageOptions.ext
        //     }
        //     state.current.messages!.push(msg)
        //     return resolve({ type, message: msg })
        //   }
        //   mo = new Websdk.message('custom', id)
        //   break
      }
      mo.set(messageOptions)
      conn.send(mo.body)
    }
  )
}

const WebIM = { config, conn }

export function useWebim () {
  const login = () => {
    WebIM.conn.open(
      {
        user: state.user.chatAccount,
        accessToken: state.accessToken,
        appKey: WebIM.config.appKey
      }
    )
  }

  const logout = () => {
    WebIM.conn.close()
    Object.assign(state, stateInit())
  }

  function changeCurrent (friend: Friend) {
    state.current = friend
    for (const message of state.current.messages!) message.isRead = true
  }

  function addRoster (roster: string) {
    WebIM.conn.addContact(roster, '加个好友呗~')
  }

  async function open (friend: FriendInfo) {
    if (state.status === Status.UnLogin)
      return Notification.info({ message: '您当前未登录即时聊天功能,请联系客服或管理员解决' })
    state.visible = true
    const [addErr] = await to(addHuanxinFriend([friend.chatAccount]))
    if (addErr) return
    const [getErr] = await to(getHuanxinFriend())
    if (getErr) return
    const current = state.friends.find((item) => item.friendInfo.chatAccount === friend.chatAccount)
    if (current) state.current = current
  }

  const flush = async (friend?: Friend) => {
    if (friend && friend.id) {
      const [err, res] = await to(getHuanxinFriendById(friend.id))
      if (err) return
      const currentFriend = state.friends.find((item) => item.id === friend.id)
      const [mesErr, mesRes] = await to(getHistoryMessages(currentFriend!.friendInfo.chatAccount, 10, false))
      if (mesErr) return
      Object.assign(currentFriend, res?.data.data[0], { messages: mesRes?.data })
    } else {
      getHuanxinFriend()
      getHuanxinGroup()
    }
  }

  //@ts-ignore
  window.im = state

  return {
    state,
    login,
    logout,
    open,
    flush,
    sendMessage,
    changeState,
    changeCurrent,
    addRoster,
    getUserInfoById,

    addHuanxinFriend,
    deleteHuanxinFriend,
    getHuanxinFriend,
    noteHuanxinFriend,
    groupHuanxinFriend,
    getHuanxinGroup,
    postHuanxinGroup,
    putHuanxinGroup,
    deleteHuanxinGroup
  }
}
