import { Ref, ref } from 'vue'
import type { ColumnProps } from 'ant-design-vue/es/table/interface'

import { isBoolean, isObject } from 'lodash-es'

import { useAjax } from '@utils/useAjax'
import type { AjaxConfig } from '@utils/useAjax'

export interface TablePagingState {
  itemCount: number
  pageIndex: number
  pageSize: number
}
interface UseTableReturn<T> {
  data: Ref<T[] | undefined>
  loading: Ref<boolean>
  columns: any
  paging: Ref<TablePagingState>
  run: () => Promise<any>
  actions: TableAction
}
interface TableConfig<T> {
  columns: ColumnProps[]
  ajax: {
    get: AjaxConfig<T>
    [Index: string]: AjaxConfig<any>
  }
  paging?: boolean | Partial<TablePagingState>
  [Index: string]: any
}

interface TableAction {
  setPaging: (pagings: Partial<TablePagingState>, cb?: () => void) => void
  getPaging: () => boolean | TablePagingState
  getColumns: () => ColumnProps[]
  [index: string]: (sth: any, cb: () => void) => void
}

export function useTable2 <T = any>(config: TableConfig<T>): UseTableReturn<T> {
  const paging = ref<TablePagingState>(
    {
      itemCount: 0,
      pageIndex: 1,
      pageSize: 10
    }
  )

  config.paging && isObject(config.paging) && Object.assign(paging.value, config.paging)

  const columns = config.columns

  const actions: TableAction = {
    setPaging: (pagings, cb) => Object.assign(paging.value, pagings) && cb && cb(),
    getPaging: () => (!isBoolean(config.paging) ? paging.value : config.paging),
    getColumns: () => config.columns
  }

  Object.entries(config).forEach(
    ([subKey, subConfig]) => {
      if (!['ajax', 'columns', 'loading', 'run'].includes(subKey)) actions[subKey] = subConfig
    }
  )

  const ajaxConfig = config.ajax.get
  ajaxConfig.addition = () => {
    return !isBoolean(config.paging)
      ? {
          pageIndex: paging.value.pageIndex,
          pageSize: paging.value.pageSize
        }
      : {}
  }
  ajaxConfig.success = (_, res) => {
    if (res.meta && res.meta.paging) Object.assign(paging.value, res.meta.paging)
  }
  const { data, loading, run } = useAjax<T>(config.ajax.get)

  return { columns, data, paging, loading, run, actions }
}
