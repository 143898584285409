import { Ref, ref, shallowRef, h } from 'vue'
import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse, Method } from 'axios'
import qs from 'qs'
import { isFunction, isArray, head, pullAt, isObject } from 'lodash-es'

import { notification, Modal, Button } from 'ant-design-vue'

export interface UseAjaxReturn<T> {
  /**
   * Axios Response
   */
  response: Ref<EnochQuoteResponse<T> | undefined>

  /**
   * Axios Response data
   */
  data: Ref<T[]>

  /**
   * Indicates if the request is currently loading
   */
  loading: Ref<boolean>

  /**
   * Any erros that may have occurred
   */
  error: Ref<AxiosError | undefined>

  /**
   * Axios run
   */
  run: () => Promise<any>
}

export interface AjaxConfig<T> {
  action: string
  params?: ((sth?: string) => any) | object | string
  addition?: object | (() => object)
  lazy?: boolean
  convert?: {
    client?: (data: T[], res: EnochQuoteResponse<T>) => T[] | any[]
    server?: (data: any) => void
  }
  success?: (data: T[], res: EnochQuoteResponse<T>) => void
  fail?: (err: AxiosError) => void
}

interface EnochQuoteResponse<T> {
  confirmations: any[]
  data: T[]
  meta?: {
    paging: {
      pageIndex: number
      pageSize: number
      pageCount: number
      itemCount: number
    }
  }
}

const ajaxInstance: AxiosInstance = axios.create(
  {
    baseURL: '/enoquote',
    headers: { ENOCH_TERMINAL: 'WEB[QUOTE]' },
    paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' })
  }
)

ajaxInstance.interceptors.response.use(
  (res) => {
    return Promise.resolve(res)
  },
  (err) => {
    if (err.response.status === 403) {
      if (!err.response.data.errors[0].shouldNotNotification) {
        // localStorage.removeItem('ENOQUOTE_ROLE')
        // window.location.replace(location.origin)
      }
      return Promise.reject(err)
    }

    if (err.response.data.errors && err.response.data.errors[0]) {
      if (!err.response.data.errors[0].shouldNotNotification) {
        notification.error({ message: err.response?.data.errors[0].message })
      }
    }

    if (err.response.data.confirmations && err.response.data.confirmations[0]) {
      let res: any, rej: any
      const config = err.config
      config.data = JSON.parse(config.data)
      config.data.confirmations = config.data.confirmations || []
      let confirmations = err.response.data.confirmations

      Modal.confirm(
        {
          maskClosable: false,
          keyboard: false,
          closable: false,
          class: 'ajax-modal',
          title: () => h('span', '警告'),
          content: () =>
            h(
              'div',
              {
                class: 'flex flex-col w-full'
              },
              [
                confirmations.map((c: any) => h('p', c.message)),
                h(
                  'div',
                  {
                    class: 'flex justify-end mt-40px'
                  },
                  [
                    h(
                      Button,
                      {
                        onClick () {
                          Modal.destroyAll()
                          rej('cancel')
                        }
                      },
                      '取消'
                    ),
                    ...confirmations[0].options.map(
                      (option: any) =>
                        h(
                          Button,
                          {
                            props: {
                              type: option.primary ? 'primary' : ''
                            },
                            class: 'ml-10px',
                            onClick () {
                              Modal.destroyAll()
                              confirmations.forEach((c: any) => (c.confirmedOption = option))
                              config.data.confirmations.push(...confirmations)
                              return res(ajaxInstance(err.config))
                            }
                          },
                          option.message
                        )
                    )
                  ]
                )
              ]
            )
        }
      )

      return new Promise(
        (resolve, reject) => {
          res = resolve
          rej = reject
        }
      )
    }
    return Promise.reject(err)
  }
)

export function useAjax <T = any>(config: AjaxConfig<T>): UseAjaxReturn<T> {
  const data = ref<T[]>([])
  const loading = shallowRef(false)
  const response = shallowRef<EnochQuoteResponse<T>>()
  const error = shallowRef<AxiosError<T>>()

  const { action, params, lazy, convert } = config
  const paramsCache = params
  const [httpVerb, path] = action.split(' ') as [Method, string]
  const run = async () => {
    loading.value = true
    let params = isFunction(paramsCache) ? paramsCache() : paramsCache
    if (!isArray(params)) params = [params]
    const instanceConfig: AxiosRequestConfig = { url: '' }
    instanceConfig.method = httpVerb
    path
      .split('/')
      .forEach(
        (str) => str && (instanceConfig.url += `/${str.startsWith(':') ? head(params) && pullAt(params, 0) : str}`)
      )

    switch (instanceConfig.method) {
      case 'get':
      case 'GET':
        instanceConfig.params = params[0]
        if (convert && convert.server) instanceConfig.params = convert.server(instanceConfig.params)
        if (config.addition) {
          if (isFunction(config.addition))
            instanceConfig.params = Object.assign({}, instanceConfig.params, config.addition())
          if (isObject(config.addition))
            instanceConfig.params = Object.assign({}, instanceConfig.params, config.addition)
        }
        break
      case 'post':
      case 'POST':
      case 'put':
      case 'PUT':
        if (params[0]) {
          instanceConfig.data = { data: [params[0]] }
          if (convert && convert.server) instanceConfig.data = convert.server(instanceConfig.data)
        }
        if (params.length > 1) instanceConfig.params = params[1]
        if (config.addition) {
          if (isFunction(config.addition)) Object.assign({}, instanceConfig.data, config.addition())
          if (isObject(config.addition)) Object.assign({}, instanceConfig.data, config.addition)
        }
        break
    }

    return ajaxInstance(instanceConfig)
      .then(
        (res: AxiosResponse<EnochQuoteResponse<T>>) => {
          response.value = res.data
          let resData = res.data.data
          data.value = convert && convert.client ? convert.client(resData, res.data) : resData
          config.success && config.success(resData, res.data)
          return res
        }
      )
      .catch(
        (err: any) => {
          error.value = err
          config.fail && config.fail(err.response)
        }
      )
      .finally(
        () => {
          loading.value = false
        }
      )
  }

  if (!lazy) run()

  return {
    //@ts-ignore
    data,
    loading,
    response,
    error,
    run
  }
}

export const ajax = ajaxInstance
