import moment from 'moment'
import accounting from 'accounting'

import { isBoolean } from 'lodash-es'

const formatDate = (value: string | number, rule?: string | boolean) =>
  value ? moment(value).format(isBoolean(rule) ? 'YYYY-MM-DD HH:mm' : rule || 'YYYY-MM-DD') : ''

const formatNumber = accounting.formatNumber
const formatMoney = accounting.formatMoney
const toFixed = accounting.toFixed

const findAreaParentName = (area: any, fullArea = ''): any => {
  fullArea += area.name + '/'
  if (area.parent) return findAreaParentName(area.parent, fullArea)
  return fullArea
    .split('/')
    .filter((i) => !!i)
    .reverse()
    .join('/')
}

const formatArea = (area: any) => (area && typeof area === 'object' ? findAreaParentName(area) : '')

export { formatDate, formatNumber, formatMoney, toFixed, formatArea }

export * from './menu'

export * from './useStore'
export * from './useAjax'
export * from './useStore'
export * from './useTable'
export * from './useTable2'
export * from './useWebim'
