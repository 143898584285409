export interface Menu {
  id: string
  message: string
  type: 'trunk' | 'leaf'
  path?: string
  children?: Menu[]
  parent?: Menu
  params?: string
}

const supMenus: Menu[] = [
  {
    id: 'SUPPLIER',
    message: '首页',
    type: 'trunk'
  },
  {
    id: 'SUPPLIER-SUPPLY',
    message: '供应',
    type: 'trunk',
    children: [
      { id: 'SUPPLIER-SUPPLY-QUOTE', message: '报价', type: 'leaf' },
      { id: 'SUPPLIER-SUPPLY-ORDER', message: '订单', type: 'leaf' }
    ]
  },
  {
    id: 'SUPPLIER-ANALYZE',
    message: '统计',
    type: 'trunk'
  },
  // {
  //   id: 'SUPPLIER-PARTNERS',
  //   message: '合作伙伴',
  //   type: 'trunk',
  //   children: [
  //     { id: 'SUPPLIER-PARTNERS-SEEK', message: '寻找伙伴', type: 'leaf' },
  //     { id: 'SUPPLIER-PARTNERS-COLLECT', message: '我的关注', type: 'leaf' }
  //   ]
  // },
  {
    id: 'SUPPLIER-INVENTORY',
    message: '共享库存',
    type: 'trunk',
    children: [
      { id: 'SUPPLIER-INVENTORY-SHARED', message: '全国共享', type: 'leaf' },
      { id: 'SUPPLIER-INVENTORY-OWNER', message: '我的库存', type: 'leaf' }
    ]
  }
]

const cliMenus: Menu[] = [
  {
    id: 'CLIENT',
    message: '首页',
    type: 'trunk'
  },
  {
    id: 'CLIENT-PURCHASE',
    message: '采购',
    type: 'trunk',
    children: [
      { id: 'CLIENT-PURCHASE-INQUIRY-_ID', message: '新建询价', type: 'leaf' },
      { id: 'CLIENT-PURCHASE-INQUIRY', message: '查看询价', type: 'leaf' },
      { id: 'CLIENT-PURCHASE-ORDER', message: '订单', type: 'leaf' }
    ]
  },
  {
    id: 'CLIENT-ANALYZE',
    message: '统计',
    type: 'trunk'
  },
  {
    id: 'CLIENT-PARTNERS',
    message: '合作伙伴',
    type: 'trunk',
    children: [
      { id: 'CLIENT-PARTNERS-SEEK', message: '寻找伙伴', type: 'leaf' },
      { id: 'CLIENT-PARTNERS-COLLECT', message: '我的关注', type: 'leaf' }
    ]
  },
  {
    id: 'CLIENT-INVENTORY',
    message: '共享库存',
    type: 'trunk',
    children: [
      { id: 'CLIENT-INVENTORY-SHARED', message: '全国共享', type: 'leaf' },
      { id: 'CLIENT-INVENTORY-OWNER', message: '我的库存', type: 'leaf' }
    ]
  }
]

const recursion = (obj: Menu) => {
  if (obj.children) {
    for (let child of obj.children) {
      child.parent = Object.assign({}, obj)
      delete child.parent.children
      recursion(child)
    }
  }
}

const assignParent = (arr: Menu[]) => {
  for (let item of arr) recursion(item)
}

assignParent(supMenus)
assignParent(cliMenus)

export { supMenus, cliMenus }
