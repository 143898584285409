import { reactive } from 'vue'

import { assign, toLower } from 'lodash-es'

import { useAjax } from '@utils/useAjax'

export interface UserState {
  id?: number
  name: string
  cellphone: string
  supplier?: SupplierState
  icon?: string
  role?: {
    code: string
    message?: string
  }
  branchName?: string
  branchId?: string
  tenantId?: string
  hxChatId?: string
}
export interface OssState {
  accessId: string
  dir: string
  host: string
  policy: string
  signature: string
  key: string
  OSSAccessKeyId: string
  Signature: string
}

export interface SupplierState {
  id?: number
  status: {
    code: string
  }
  guaranteePeriod?: number
  shortName: string
  followerCount: number
  area?: {}
  businessType: {
    code: string
  }
}
interface StoreState {
  user: UserState
  oss: OssState
  ossData: OssState
  supplier: SupplierState
}

interface StoreActions {
  changeState: (state: Partial<StoreState>) => void
  changeUser: () => Promise<any>
  changeOss: () => Promise<OssState>
  changeSupplier: () => Promise<SupplierState>
  reset: () => void
}

const stateInit = () => ({
  user: {
    name: '',
    cellphone: ''
  },
  oss: {
    accessId: '',
    dir: '',
    host: '',
    policy: '',
    signature: '',
    key: '',
    OSSAccessKeyId: '',
    Signature: ''
  },
  ossData: {
    accessId: '',
    dir: '',
    host: '',
    policy: '',
    signature: '',
    key: '',
    OSSAccessKeyId: '',
    Signature: ''
  },
  supplier: {
    status: {
      code: ''
    },
    shortName: '',
    followerCount: 0,
    businessType: {
      code: ''
    }
  }
})

const state = reactive<StoreState>(stateInit())

const actions: StoreActions = {
  changeState: (statee: Partial<StoreState>) => assign(state, statee),
  changeUser: async () => {
    let role = localStorage.getItem('ENOQUOTE_ROLE') || ''
    if (!role) return Promise.reject('ENOQUOTE_ROLE undefinded')

    return new Promise(
      (resolve, reject) => {
        useAjax<UserState>(
          {
            action: `GET /${toLower(role)}/security/user`,
            success (data) {
              actions.changeState({ user: data[0] })
              resolve(state.user)
            },
            fail (err) {
              reject(err)
            }
          }
        )
      }
    )
  },
  changeOss: async () => {
    return new Promise(
      (resolve, reject) => {
        useAjax<OssState>(
          {
            action: 'POST /supplier/common/oss/signature',
            success (data) {
              actions.changeState({ oss: data[0] })
              resolve(state.oss)
            },
            fail (err) {
              reject(err)
            }
          }
        )
      }
    )
  },
  changeSupplier: async () => {
    return new Promise(
      (resolve, reject) => {
        if (localStorage.getItem('ENOQUOTE_ROLE') === 'CLIENT') return resolve(state.supplier)
        useAjax<SupplierState>(
          {
            action: 'GET /supplier/security/currentsupplier',
            success (data) {
              actions.changeState({ supplier: data[0] })
              resolve(state.supplier)
            },
            fail (err) {
              reject(err)
            }
          }
        )
      }
    )
  },
  reset: () => {
    assign(state, stateInit())
  }
}

export function useStore () {
  return {
    state,
    actions
  }
}
